import cn from 'classnames';
import { FC } from 'react';

import s from './StarRating.module.scss';

interface Props {
  rating: number;
  color?: string;
  maxNumOfStars?: number;
  className?: string;
}

const getFilledPercent = (index: number, rating: number): number => {
  const decimal = rating - index;

  if (decimal >= 1) {
    return 100;
  }

  if (decimal > 0) {
    return decimal * 100;
  }

  return 0;
};

const Star = ({
  index,
  rating,
  color,
  className,
}: {
  index: number;
  rating: number;
  color: string;
  className?: string;
}) => {
  const filledPercent = getFilledPercent(index, rating);

  if (filledPercent <= 0) {
    return null;
  }

  return (
    <span key={index} className="relative inline-flex" data-testid="star">
      <span
        data-testid="filled-star"
        className={cn(s.filling, color, className)}
        style={{ width: `${filledPercent}%` }}
      >
        ★
      </span>
      {/* just to set the width */}
      <span className={cn(s.star, className)}>★</span>
    </span>
  );
};

const StarRating: FC<Props> = ({ rating, maxNumOfStars = 5, color = s.defaultColor, className }) => {
  return (
    <span data-testid="rating-container">
      {Array.from({ length: maxNumOfStars }, (_, index) => index).map((index) => (
        <Star key={index} index={index} rating={rating} color={color} className={className} />
      ))}
    </span>
  );
};

export default StarRating;
