import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

import checkMobileView, { MobileBreakpoint } from '@lib/check-mobile-view';

export function useMobileViewport(breakpoint?: MobileBreakpoint) {
  const [isMobile, setIsMobile] = useState(checkMobileView(breakpoint));

  const onResize = () => {
    setIsMobile(checkMobileView(breakpoint));
  };

  useEffect(() => {
    onResize();
    const throttledHandler = throttle(onResize, 200);
    window.addEventListener('resize', throttledHandler);

    return () => {
      window.removeEventListener('resize', throttledHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobile;
}
