import { Experience } from '@ninetailed/experience.js-next';
import isEmpty from 'lodash/isEmpty';
import { FC } from 'react';

import type { ScreenPageContent } from '@components/common/types/Page';
import { useDynamicComponents } from '@components/screen/useDynamicComponents';

import { ModuleExperienceSelector } from './experienceSelector';
import { NtConfigurable, NtPersonalizable } from './types';

export interface Props extends NtPersonalizable {
  currencyCode: string;
  locale: string;
  page: NtConfigurable<ScreenPageContent>;
  baseline: ScreenPageContent;
}

const Screen: FC<Props> = ({ page, locale, currencyCode, baseline, ninetailed }) => {
  const pageToRender = page.contentModules?.length ? page : baseline;
  const components = useDynamicComponents(pageToRender.contentModules);

  return (
    <div
      data-ab-screen-personalized={ninetailed?.isPersonalized ?? false}
      data-ab-screen-assigned={page.internalName}
      data-ab-screen-rendered={pageToRender.internalName}
    >
      {components.map(({ id, Component, mapper, props, experiences }) =>
        isEmpty(experiences) ? (
          <Component key={id} {...(mapper?.(props) ?? props)} locale={locale} currencyCode={currencyCode} />
        ) : (
          <Experience
            key={id}
            id={id}
            experiences={experiences}
            component={ModuleExperienceSelector}
            {...(mapper?.(props) ?? props)}
            // props that need to be passed to both the baseline AND the variant
            passthroughProps={{
              currencyCode,
              locale,
              baseline: {
                component: Component,
                mapper,
              },
            }}
          />
        )
      )}
    </div>
  );
};

export default Screen;
