import cn from 'classnames';
import { FC } from 'react';

import usePrice from '@framework/product/use-price';

import { Badge as BadgeProps, Price } from '../types';

import style from './Badge.module.scss';

interface Props extends BadgeProps {
  className?: string;
}

const getDiscount = (pricing: Price): number => {
  const { originalPrice, price } = pricing;
  return originalPrice! - price;
};

const Badge: FC<Props> = ({ text, color, bgColor, price: pricing, isPill = false, className }) => {
  const discount = pricing && getDiscount(pricing);
  const { price: discountLabel } = usePrice({
    amount: Number(discount || undefined),
    currencyCode: pricing?.currencyCode,
    decimals: 0,
  });

  return (
    <div
      className={cn(style.badge, { '!rounded-full': isPill }, className)}
      style={{
        backgroundColor: bgColor ? `var(--${bgColor})` : undefined,
        color: color ? `var(--${color})` : undefined,
      }}
    >
      {`${text}${discountLabel ? ` ${discountLabel}` : ''}`}
    </div>
  );
};
export default Badge;
